.services_section{
    width: 100%;
    float: left;
    background-image: url(../../../assets/services-bg.png)!important;
    background-size: 100%;
    background-repeat: no-repeat;
}
.services_taital {
    width: 100%;
    float: left;
    font-size: 40px;
    color: #0f0f0f;
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
  margin-bottom: 3rem;
}
.services_taital::after {
    content: '';
    position: absolute;
    width: 193px;
    height: 2px;
    background-color: #0f0f0f;
    left: initial;
    right: 0px;
    top: 44%;
}
.services_section_2 {
    width: 100%;
    float: left;
    padding-bottom: 50px;
}
.icon_box {
    width: 100px;
    padding: 10px;
    background-color: #fff;
    box-shadow: 0px 0px 2px 2px #edf0f2;
    border-radius: 3px;
    margin-top: 30px;
}
.icon_1{
    width: 100%;
    text-align: center;
}
.selection_text{
    width: 100%;
    font-size: 20px;
    color: #0f0f0f;
    font-weight: bold;
    padding-top: 20px;
    font-family: 'Roboto', sans-serif;
    text-align: left;
}
.ipsum_text {
    width: 68%;
    font-size: 15px;
    color: #0f0f0f;
    margin: 0px;
}

.layout_padding {
    padding-top: 90px;
    padding-bottom: 0px;
}

@media screen and (max-width:768px){
    .services_section {
        background-color: #edf0f2;
    }
}