#gtco-special-dishes .special-number {
    position: relative;
    font-size: 60px; }
    #gtco-special-dishes .special-number:after {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 90px;
      width: 200px;
      height: 1px;
      background: #000; }
  
  #gtco-special-dishes h3 span {
    color: #f34949; }
  
  .dishes-text {
    padding-left: 90px; }
  

  .only-mobile {
    display: none !important; }
  
  @media (max-width: 992px) {
    .hero {
      height: 100%; }
    .boxed-page {
      padding-top: 100px; }
    #navbar-header {
      position: fixed;
      top: 0;
      height: auto;
      width: 100%;
      background-color: #fff;
      z-index: 11;
      padding-top: 15px;
      padding-bottom: 15px; }
      #navbar-header .dropdown-menu {
        box-shadow: none !important; }
      #navbar-header .navbar-collapse {
        margin-top: 15px;
        background: #fff !important; }
    .only-mobile {
      display: block !important; }
    .only-desktop {
      display: none !important; }
    .menu-wrap {
      margin-bottom: 30px; }
    .footer-widget {
      margin-bottom: 30px; }
    .hero-left {
      padding-top: 30px;
      padding-bottom: 30px; } }
  
  @media (max-width: 767px) {
    .hero-left,
    .hero-carousel .owl-item img {
      height: 600px; }
    .thumb-menu img {
      height: 90px; }
    .img-2 {
        height: 500px; } }

        .softtopimg{
          height: 30rem;
        }
        .softtop-1{
          padding-top: 2.5rem;
        }
        @media (max-width: 767px){
        .softtopimg{
          height: 18rem;
        }
      }