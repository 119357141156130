.Reasons{
    padding: 4rem;
    display: flex;
    gap: 2rem;
    background-color: rgba(39, 153, 214, 0.041);
}
.left-r{
flex: 1 1;
display: grid;
grid-template-columns: repeat(3,1fr);
gap: 0.5rem;
grid-auto-rows: 1fr;
}
.right-r{
    flex: 1 1;
gap: 1rem;
display: flex;
flex-direction: column;
}
.right-r>span{
font-weight: bold;
color: white;
text-transform: uppercase;
font-size: 3rem;
}

 .details-r{
    font-size: 1.3rem;
    color: aliceblue;
 }  

.left-r>img{
    object-fit: cover;
}
.left-r>:nth-child(1){
    width: 22rem;
    grid-row: 1/3;
    height: 28rem;
    /* border-radius: 1rem; */
}
.left-r>:nth-child(2){
    width: auto;
    height: 15rem;
    grid-column: 2/3;
    /* border-radius: 1rem; */
}
.left-r>:nth-child(3){
    width: 23.5rem;
    height: 12.5rem;
    grid-column: 2/3; 
    /* border-radius: 1rem; */
}
@media screen and (max-width:768px){
.Reasons{
    flex-direction: column;
    padding: 2rem;
    margin-top: 19rem;
}   

.left-r{
    grid-auto-rows: auto;
    overflow: hidden;
}
.details-r{
    font-size: 1rem;
}
.left-r>:nth-child(1){
    width: 9rem;
    height: 18rem;
}
.left-r>:nth-child(2){
    width: 12rem;

    height: 9rem;
    
}
.left-r>:nth-child(3){
    width: 12rem;
    height: 8rem;
    
}
.right-r>span {
    font-size: 1.5rem;
}
}