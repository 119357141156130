.client-img{
    padding-left: 10rem;
    padding-right: 10rem;
}
.client-img2{
    padding-left: 18rem;
    padding-right: 18rem;
}
.client-img img{
    padding: 1rem;
    height: 9rem;
    transition: transform 1s;
}
.client-img img:hover{
    transform: scale(1.25);
    cursor: pointer;
}

.client-img2 img {
    padding: 1.5rem;
    height: 6rem;  
    transition: transform 1s;
}
.client-img2 img:hover{
    transform: scale(1.5);
    cursor: pointer;
}
.client h1,h4{
    text-align: center;
    padding: 1rem;
}
.client{
    padding: 2rem 6rem 6rem;
}
.client h4{
    margin-bottom: 2rem;
}
.client{
background-color: rgb(255, 255, 255);

}
.client .card{
    border: none!important;
}@media screen and (max-width:768px)
{
    .client{
        padding: 2rem;
    }
    .client-img {
        padding: 0;
    }
    .client-img2 {
        padding: 0;
    }
}