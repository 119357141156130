.about_section {
    width: 100%;
    float: left;
    padding-bottom: 90px;
    /* background-color: #ffffff; */
}
.about_section h1{
    color: #ffffff;
}

.rectangle{
    width: 50px;
    height: 50px;
    /* position: absolute; */
    /* top: 5px; */
    color: black;

}
.about_section_2 {
    width: 100%;
    float: left;
    padding-top: 70px;
    padding-bottom: 90px;
}
.about_image {
   
    float: left;
    padding-left: 22px;
    padding-top: 37px;
    position: relative;
    z-index: 1;
}
.about_image img{
    width: 30rem;
}

.about_image::after {
    content: '';
    position: absolute;
    width: 200px;
    height: 390px;
    background-color:var(--blue);
    left: -10px;
    right: initial;
    top: 5px;
    z-index: -1;
}

.about_taital {
    width: 35%;
    float: left;
    font-size: 40px;
    color: #0f0f0f;
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
    position: relative;
    z-index: 1;
   
}
.about_taital::after {
    content: '';
    position: absolute;
    width: 193px;
    height: 2px;
    background-color: #252525;
    left: initial;
    right: 0px;
    top: 44%;
    z-index: -1;
}
.about_text {
    width: 100%;
    float: left;
    font-size: 1.25rem;
    color: #ffffff;
    margin: 0px;
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
    padding-top: 1rem;
}
.lorem_text {
    width: 100%;
    float: left;
    font-size: 16px;
    color: #ffffff;
    margin: 0px;
    font-family: 'Roboto', sans-serif;
}
.about_taital_main {
    width: 100%;
    float: left;
    padding-top: 172px;
}
.read_bt {
    width: 170px;
    float: right;
    padding-top: 64px;
}
.read_bt a {
    width: 100%;
    float: left;
    font-size: 16px;
    color: #ffffff;
    background-color: var(--blue);
    text-align: center;
    padding: 10px 0px;
    font-family: 'Roboto', sans-serif;
}
.read_bt a:hover {
    color: #ffffff;
    background-color: rgb(6, 6, 193);
}