.hero{
    /* display:flex; */
    /* justify-content: space-between; */
    /* z-index: 1; */
    position: absolute;
    top: 0;
    width: 100%;

   
}

.left-h{
    padding: 2rem;
    padding-top: 8rem;
    flex: 3;
    display: flex;
    gap: 2rem;
    flex-direction: column;
}
.right-h{
    flex: 1;
  
    position: relative;
}
.the-best-comp{
    background-color: antiquewhite;
    margin-top: 4rem;
    border-radius: 4rem;
    width: fit-content;
    padding: 20px 13px;
    text-transform: uppercase;
    color: rgb(9, 9, 9);
    display: flex;
    justify-content: flex-start;
    position: relative;
    align-items: center;
}
.the-best-comp>span{
    z-index: 2;
}

.the-best-comp>div{
position:absolute;
background-color: var(--blue);
width: 5.4rem;
height:80%;
left:8px;
border-radius: 3rem;
z-index: 1;
}
.hero-text>div{
    font-size: 1.5rem;
    font-weight: 200;
    text-transform: none;
    letter-spacing:1px;
    width: 80%;
    margin-top: 1rem;
    color: aliceblue;
}
.figures{
    display: flex;
    gap: 2rem;
    margin-top: 4rem;
}
.figures>div{
    display: flex;
    flex-direction: column;
}
.figures>div>span:nth-of-type(1){
    color: white;
    text-transform: none;
    font-size: 2.5rem;
}
.figures>div>span:nth-of-type(2){
    color: var(--gray);
    text-transform: capitalize;
    font-size: 1.2rem;
}
.hero-button{
    display: flex;
    gap: 1rem;
    font-weight: normal;

}
.hero-button>:nth-child(1)
{
color: white;
background-color: var(--blue);
width: 8rem;
margin-top: 4rem;
}
.hero-button>:nth-child(2){
    color: white;
    background-color: transparent;
    width: 8rem;
    border:2px solid var(--blue) ;
    margin-top: 4rem;
}
.right-h>.btn{
    position: absolute;
    right:3rem; 
    top: 2rem;
    color: black;
}

@media screen and (max-width:768px){
    .hero{
        flex-direction: column;
    }
    .hero-text>div{
font-size: 1.25rem;
    }
  
.hero-text>span:nth-of-type(1) {
    font-size: 1rem;
    font-size: 2.2rem!important;
}
    .figures>div>span:nth-of-type(1){
       
        font-size: 1.5rem;
    }
    .figures{
        flex-direction: column;
    }
    .the-best-comp{
        padding: 14px 13px;
    }
  
}