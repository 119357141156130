.accord{
    padding: 5rem;
}
.accord h1{
    color: aliceblue;
    padding-bottom: 2rem;
    text-align: center;
}
.accordion-button:not(.collapsed) {
    color: #000000;
    background-color: #ffffff;
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}
.accordion-item {
    background-color: #fff;
    border: 1px solid rgba(0,0,0,.125);
    margin: 1rem;
}
.faq-background{
    background-image: url(../../assets/adidas.png);
    background-repeat: no-repeat;
    background-size: cover;
    height: 50rem;
    width: 100%;
}
@media screen and (max-width:768px){
    .accord{
        padding: 0;
    }
    .faq-background{
    height: 14rem;
    }
    

}
