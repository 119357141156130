.aboutseo{
    color: white;
}
.serp ,.organictraffic{
    text-align: left;
    padding-left: 0!important;

}
.service {
    position: absolute;
    content: "";
    width: 100%;
    /* height: calc(100% - 45px); */
    height: 22rem;
    top: 135px;
    left: 0;
    background: linear-gradient(rgba(43, 40, 37, 0.882), rgba(43, 40, 37, 0.644)), url(../../../assets/seo1.jpg) center center no-repeat;
    background-size: cover;
    z-index: 1;
}
.bgcolorr{
   background-color: hsl(193deg 100% 50% / 56%);
} 
.seoback{
    background-image: url(../../../assets/seoback.jpg);
    height: 60rem;
    background-position: center;
}

@media screen and (max-width:768px){
.service{
    height: 73rem;
}
}