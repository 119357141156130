.contact_section {
    width: 100%;
    float: left;
    background-image: url(../../assets/contact-bg.png);
    height: auto;
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 90px;
}
.contact_taital {
    width: 40%;
    float: left;
    font-size: 40px;
    color: #ffffff;
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
}
.contact_taital::after {
    content: '';
    position: absolute;
    width: 193px;
    height: 2px;
    background-color: #ffffff;
    left: initial;
    right: 72rem;
    top: 14%;
}
.contact_text {
    width: 100%;
    float: left;
    font-size: 16px;
    color: #ffffff;
    margin: 0px;
   font-family: 'Roboto', sans-serif;
}
.contact_section_2{
    width: 100%;
    float: left;
    padding-top: 0px!important;
}
.mail_text {
    width: 100%;
    float: left;
    font-size: 16px;
    color: #727272;
    border: 0px;
    background-color: #ffff;
    padding: 15px;
    margin-top: 20px;
   font-family: 'Roboto', sans-serif;
}
input.mail_text::placeholder {
    color: #727272;
}
.massage-bt {
    color: #727272;
    width: 100%;
    font-size: 18px;
    background-color: #ffff;
    padding: 55px 15px 15px 15px;
    border: 0px;
    height: 167px;
    margin-top: 20px;
   font-family: 'Roboto', sans-serif;
}
textarea#comment::placeholder {
    color: #727272;
}
.map_main{
    width: 100%;
    float: left;
    padding-top: 20px;
}

.send_bt {
    width: 150px;
    float: left;
    margin-top: 40px;
}
.send_bt a {
    width: 100%;
    float: left;
    background-color:var(--blue);
    color: #ffffff;
    text-align: center;
    padding: 14px 0px;
    font-size: 16px;
   font-family: 'Roboto', sans-serif;
}
.send_bt a:hover {
    background-color: #ffffff;
    color: #000;
}
.contact-details i{
    font-size: 35px;
    color:#fff;
}
.contact-details h6{
    margin: 20px 0;
    color:#fff;
    font-weight: 200;
    font-size: 1.15rem;
   
}