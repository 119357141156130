.about-start,
.about-end p{
    /* background: url(../../../assets/mission.jpg) center center no-repeat; */
   color: aliceblue;
}
.display-5{
    color: aliceblue;
}
@media (min-width: 992px) {
    .about-start {
        position: relative;
        margin-right: -90px;
        z-index: 1;
    }

    .about-end {
        position: relative;
        margin-left: -90px;
        z-index: 1;
    }
}
.about-end span:nth-child(1)
{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 6px;
    background: linear-gradient(to right,transparent,rgb(0, 8, 254));
    animation: animate1 2s linear ;
}
@keyframes animate1{
    0%{
        transform:translateX(-0%)
    }
    50%{
        transform:translateX(50%)
    }
}
.about-end span:nth-child(2)
{
    position: absolute;
    top: 0;
    right: 0;
    width: 6px;
    height: 100%;
    background: linear-gradient(to bottom,transparent,rgb(0, 8, 254));
    animation: animate2 2s linear ;
    animation-delay: 1s;
}
@keyframes animate2{
    0%{
        transform:translateX(-100%)
    }
    100%{
        transform:translateX(100%)
    }
}
.about-end span:nth-child(3)
{
    position: absolute;
    bottom: 0;
    left: 0px;
    width: 100%;
    height: 6px;
    background: linear-gradient(to left,transparent,rgb(0, 8, 254));
    animation: animate3 2s linear ;
}
@keyframes animate3{
    0%{
        transform:translateX(0%)
    }
    50%{
        transform:translateX(-50%)
    }
}
.about-end span:nth-child(4)
{
    position: absolute;
    top: 0;
    left: 0;
    width: 6px;
    height: 100%;
    background: linear-gradient(to top,transparent,rgb(0, 8, 254));
    animation: animate4 2s linear ;
    animation-delay: 1s;
}
@keyframes animate4{
    0%{
        transform:translateX(100%)
    }
    100%{
        transform:translateX(-100%)
    }
}
.about-start span:nth-child(1)
{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 6px;
    background: linear-gradient(to right,transparent,rgb(0, 8, 254));
    animation: animate1 2s linear ;
}
@keyframes animate1{
    0%{
        transform:translateX(-0%)
    }
    50%{
        transform:translateX(50%)
    }
}
.about-start span:nth-child(2)
{
    position: absolute;
    top: 0;
    right: 0;
    width: 6px;
    height: 100%;
    background: linear-gradient(to bottom,transparent,rgb(0, 8, 254));
    animation: animate2 2s linear ;
    animation-delay: 1s;
}
@keyframes animate2{
    0%{
        transform:translateX(-100%)
    }
    100%{
        transform:translateX(100%)
    }
}
.about-start span:nth-child(3)
{
    position: absolute;
    bottom: 0;
    left: 0px;
    width: 100%;
    height: 6px;
    background: linear-gradient(to left,transparent,rgb(0, 8, 254));
    animation: animate3 2s linear ;
}
@keyframes animate3{
    0%{
        transform:translateX(0%)
    }
    50%{
        transform:translateX(-50%)
    }
}
.about-start span:nth-child(4)
{
    position: absolute;
    top: 0;
    left: 0;
    width: 6px;
    height: 100%;
    background: linear-gradient(to top,transparent,rgb(0, 8, 254));
    animation: animate4 2s linear ;
    animation-delay: 1s;
}
@keyframes animate4{
    0%{
        transform:translateX(100%)
    }
    100%{
        transform:translateX(-100%)
    }
}
.visionmision{
    padding-top: 117rem!important;

}
