#about {
    background: url("../../../assets/accounting1.jpg") center top no-repeat fixed;
    background-size: cover;
    padding: 60px 0 40px 0;
    position: relative;
  }
  
  #about::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgb(239 237 237 / 84%);
    z-index: 9;
  }
  
  #about .container {
    position: relative;
    z-index: 10;
  }
  
  #about .about-col {
    background: #fff;
    border-radius: 0 0 4px 4px;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
    margin-bottom: 20px;
  }
  
  #about .about-col .img {
    position: relative;
  }
  
  #about .about-col .img img {
    border-radius: 4px 4px 0 0;
  }
  
  #about .about-col .icon {
    width: 64px;
    height: 64px;
    text-align: center;
    position: absolute;
    background-color: var(--blue);
    border-radius: 50%;
    border: 4px solid #fff;
    left: calc(50% - 32px);
    bottom: -30px;
    transition: 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  #about .about-col i {
    font-size: 28px;
    line-height: 0;
    color: #fff;
    transition: 0.3s;
  }
  
  #about .about-col:hover .icon {
    background-color: #fff;
  }
  
  #about .about-col:hover i {
    color: var(--blue);
  }
  
  #about .about-col h2 {
    color: #ffffff;
    text-align: center;
    font-weight: 700;
    font-size: 20px;
    padding: 0;
    margin: 40px 0 12px 0;
  }
  
  #about .about-col h2 a {
    color: #ffffff;
  }
  
  #about .about-col h2 a:hover {
    color: var(--blue);
  }
  
  #about .about-col p {
    font-size: 14px;
    line-height: 24px;
    color: #333;
    margin-bottom: 0;
    padding: 0 20px 20px 20px;
  }
  .section-header{
    margin-top: 5rem;
  }
  .section-header p{
    font-size: 1.2rem;
    padding: 2rem;
 
  }
  .asofttop-1{
    font-size: 1.1rem;
    margin-top: 2rem;
  }
  .accountback{
    background-image: url(../../../assets/accountback.jpg);
    height: 60rem;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
