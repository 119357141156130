.whyus{
    display: flex;
    gap: 1rem;
    padding: 5.5rem;
    margin-bottom: 4rem;
   
}
.left-w{
    display: flex;
    flex: 1 1;
    gap: 2rem;
    flex-direction: column;
   
    color: white;
}     
.left-w>:nth-child(1){
    font-weight: bold;
    font-size:3rem;
}   
.left-w>:nth-child(2){
    font-size: 1.3rem;
    font-weight: 300;
}
.left-w>:nth-child(3){
    font-size: 2rem;  
    font-weight: 500;
   text-transform: uppercase;
color: var(--blue);

}
.left-w>:nth-child(4){
    text-align: justify;
    line-height: 1.5rem;
}
.right-w{
    flex: 1;
    position: relative;
}
.right-w>img{
    position: absolute;
    width: 26rem;
    height: 21rem;
    object-fit: cover;
    right: 8rem;
    top: 2rem;
}
.right-w>:nth-child(1){
    position: absolute;
    width: 26rem;
    height: 20rem;
    border: 2px solid var(--blue);
    background-color: transparent;
    right: 9rem;
    top: 0.9rem;
}
.right-w>:nth-child(2){
    position: absolute;
    width: 26rem;
    height: 19rem;
    right: 6.5rem;
    top: 5.5rem;
    background: var(--planCard);
}
.arrows{
    display: flex;
    gap: 1rem;
    position: absolute;
    bottom: -7rem;
    left: 3rem;
}
.arrows> img{
    width: 1.5rem;
    cursor: pointer;
}
@media screen and (max-width:768px){
    .whyus{
        
        flex-direction: column;
        padding: 2rem;
        height: 64rem;
    }
    .right-w{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 2rem;
        
    }
    .right-w>div{
position: relative;
display: none;
    }
    .right-w>img{
      top: 0;  
      right: 0;
      position: relative;
      align-self: center;
    }
    .right-w>:last-child{
        display: block;
        bottom: 0;
        left: 0;

    }

}
@media screen and (max-width:574px){


}