.barcodeimg{
    background-image: url(../../../assets/barcodeback.jpg);
    height: 25rem;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 1rem;
}
.barcodebackk{
    background-image: url(../../../assets/barcodebackk.jpg);
    height: 60rem;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}