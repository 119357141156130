ul {
    list-style: none;
    padding-left: 0!important;
  }
  .footer {
    /* background-image: url("backhome.jpg"); */
    padding: 70px 0;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .footer-col {
    width: 25%;
    padding: 0 15px;
   
  }
  .footer h4{
    padding-left:0 ;
  }
  .footer-col h4 {
    font-size: 18px;
    color: #ffffff;
    text-transform: capitalize;
    margin-bottom: 35px;
    font-weight: 500;
    position: relative;
    text-align: left;
  }
  .footer-col h4::before {
    content: "";
    position: absolute;
    left: 1px;
    bottom: -10px;
    background-color: var(--blue);
  
    height: 2px;
    box-sizing: border-box;
    width: 50px;
  }
  .footer-col ul li:not(:last-child) {
    margin-bottom: 10px;
  }
  .footer-col ul li a {
    font-size: 16px;
    text-transform: capitalize;
    color: #ffffff;
    text-decoration: none;
    font-weight: 300;
    color: #bbbbbb;
    display: block;
    transition: all 0.3s ease;
  }
  .footer-col ul li a:hover {
    color: #ffffff;
    padding-left: 8px;
  }
  .footer-col .social-links a {
    display: inline-block;
    height: 40px;
    width: 40px;
    background-color: rgba(255, 255, 255, 0.2);
    margin: 0 10px 10px 0;
    text-align: center;
    line-height: 40px;
    border-radius: 50%;
    color: #ffffff;
    transition: all 0.5s ease;
  }
  .footer-col .social-links a:hover {
    color: #24262b;
    background-color: #ffffff;
  }
  
  /*responsive*/
  @media (max-width: 767px) {
    .footer-col {
      width: 50%;
      margin-bottom: 30px;
    }
  }
  @media (max-width: 574px) {
    .footer-col {
      width: 100%;
    }
  }