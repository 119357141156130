.appbackimg{
    background-image: url(../../../assets/appdevback.avif);
    height: 40rem;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.about {
    background: #fff;
    padding: 80px 50px;
    position: relative;
    margin: 0 70px;
    box-shadow: 3px 0 62px rgb(13 3 3 / 18%);
    border-radius: 42px;
    top: -3rem;
 
}

.container_width {max-width: 1380px;
 padding: 0 15px;
 display: block;
 margin: 0 auto;
 clear: both;


}

.about .titlepage {
     padding-bottom: 0;
}
.about .titlepage h2 {
    position: relative;
    padding-bottom: 18px;
}

.about .titlepage h2::before {
    position: absolute;
    content: "";
    bottom: -8px;
    background: var(--blue);
    width: 109px;
    height: 8px;
    left: 0;
    border-radius: 20px;
}
.about .titlepage p {
    font-weight: normal;
    font-size: 17px;
    line-height: 30px;
    color: #111111;
    padding: 35px 0px 50px 0px;
}


.about_img figure {
     margin: 0;
}

figure img{
   height: 19rem; 
}
.mobileappdev{
    margin-top: 3rem;
    font-size: 1.25rem;
}
.mobileappdev li{
  list-style-type: disc;
  
}
.text_align_center li{
    list-style-type: none;
    text-align: center;
   
}
.text_align_center {
    justify-items: start;
}


/** end about section **/


/** coronata section **/

.coronata {
    background: #fff;
    padding-top: 100px;
    height: 25rem;
}

.about .titlepage h2{
    color: #000000;
}
.titlepage h2 {
    color: #000000;
}

.coronata .titlepage {
     padding-bottom: 0;
}

.coronata .titlepage p {
    font-weight: normal;
    font-size: 17px;
    line-height: 30px;
    color: #111111;
    padding: 35px 0px 50px 0px;
}


.coronata_img figure {
    margin: -54px;
    padding-left: 12rem;
}


/** end coronata section **/



/** protect **/

.protect {
    padding: 0 30px;
     padding-top: 80px;
    padding-bottom: 25px;
}


.protect_bg  {
       /* background:url(../images/project.png); */
     background-repeat: no-repeat;
     background-position: center;
     background-size: 100% 100%;
       padding-top: 80px;
      padding-bottom: 48px;
}

.protect .titlepage p {
     padding-top: 5px;
   
}
.protect h2{
    color: #fff!important;
    text-align: center;
}
.protect p{
    color: #fff!important;
    text-align: center;
}
.desktop {
    padding: 45px 20px 30px 20px;
    background: #fff;
    border-radius: 20px;
    text-align: center;
}


.protect_box h3 {
     color: #0d0e0e;
     font-size: 17px;
     font-weight: bold;
     padding-top: 25px;
     padding-bottom: 5px;
 
}

.protect_box span {
     color: #081419;
     font-weight:normal;
}
.protect .read_more {
    margin: 0 auto;
    display: block;
    max-width: 174px;
    height: 51px;
    line-height: 51px;
    font-weight: bold;
    font-size: 15px !important;
    margin-top: 40px;

}
.owl-carousel .owl-item img {
    width: inherit !important;
    text-align: center;
    margin: 0 auto;
    height: 90px;
}

.owl-carousel .owl-nav.disabled {
     display: inherit !important;
    padding-top: 80px;
}


.owl-carousel .owl-nav button.owl-next, 
.owl-carousel .owl-nav button.owl-prev {
     width: 90px;
     height: 90px;
     background-color: #ed1c24 !important;
     color: #fff !important;
     font-size: 55px !important;
     line-height: 30px !important;
     opacity: 1;
     border-radius: 50px;
     transition: ease-in all 0.5s;
     box-shadow: 0 0 80px rgba(13, 3, 3, 0.11);
}
.owl-carousel .owl-nav button.owl-next:hover, 
.owl-carousel .owl-nav button.owl-prev:hover {
     background-color: #fff !important;
     transition: ease-in all 0.5s;
     color: #000 !important;
}
.owl-next {
    position: absolute;
    right: 42%;
}
.owl-prev {
    position: absolute;
    left: 42%;
}

.desktop img{
    height: 15rem;
}
.item{
    margin-bottom: 5rem;
}
@media screen and (max-width:768px){
    .about{
        padding: 35px 16px;
        margin: 0px 16px;
    }
    figure img {
        height: 13rem;
    }
    .appdevimg-1{
        visibility: hidden;
    }
    .coronata_img figure {
        margin: -96px;
    }
        .desktop img {
            height: 12rem;
        }
}