.about-crm .content h3 {
    font-weight: 700;
    font-size: 34px;
    color: #fefefe;
  }
  
  .about-crm .content p {
    margin-bottom: 0;
  }
  
  .about-crm .content .icon-box h4 {
    font-size: 20px;
    font-weight: 700;
    /* margin: 5px 0 10px 60px; */
    color: #76b2e8;
  }
  
  .about-crm .content .icon-box h4 a {
    color: #8bc5f8;
    transition: 0.3s;
  }
  
  .about-crm .content .icon-box h4 a:hover {
    color: var(--blue);
  }
  
  .about-crm .content .icon-box i {
    font-size: 48px;
    float: left;
    color: var(--blue);
  }
  
  .about-crm .content .icon-box p {
    font-size: 15px;
    color: #e4dfdf;
    margin-left: 60px;
  }
  
  .about-crm .image {
    background: url("../../../assets/about-crm.jpg") center center no-repeat;
    background-size: cover;
    min-height: 400px;
  }
  
  @media (max-width: 667px) {
    .about-crm .image img {
      max-width: 100%;
    }
  }
  .about-crm{
    margin-top: 10rem;
    padding-bottom: 10rem;
  }
  .services {
    padding-bottom: 40px;
  }
  
  .services .card-crm {
    border: 0;
    padding: 0 30px;
    margin-bottom: 60px;
    position: relative;
    background: transparent;
    margin-bottom: 8rem;
  }
  
  .services .card-crm-img {
    overflow: hidden;
    z-index: 9;
    border-radius: 0;
  }
  
  .services .card-crm-img img {
    max-width: 100%;
    transition: all 0.3s ease-in-out;
  }
  
  .services .card-crm-body {
    z-index: 10;
    background: #fff;
    border-top: 4px solid #fff;
    padding: 30px;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    transition: 0.3s;
    margin-top: -60px;
    margin-left: 30px;
    width: calc(100% - 60px);
    position: relative;
  
  }
  
  @media (max-width: 640px) {
    .services .card-crm-body {
      margin-left: 15px;
      width: calc(100% - 30px);
    }
  }
  
  .services .card-crm-title {
    font-weight: 700;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .services .card-crm-title a {
    color: #213b52;
    transition: 0.3s;
  }
  
  .services .card-crm-text {
    color: #5e5e5e;
  }
  
  .services .read-more a {
    color: #777777;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
    transition: 0.3s;
  }
  
  .services .read-more a:hover {
    color: var(--blue);
  }
  
  .services .card-crm:hover img {
    transform: scale(1.1);
  }
  
  .services .card-crm:hover .card-crm-body {
    border-color: var(--blue);
  }
  
  .services .card-crm:hover .card-crm-body .card-crm-title a {
    color: var(--blue);
  }
  .crmback{
    background-image: url(../../../assets/crmback.jpg);
    height: 60rem;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  @media (max-width: 767px){
    .card-crm-img {
        height: 15rem;
    }
  }