
@media (max-width: 767px) {
  .service__area {
    padding-top: 60px;
    padding-bottom: 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__area .section-title {
    margin-bottom: 20px;
  }
}
.service__text {
  padding-top: 8px;
}
.section-title h2,h5{
  padding: 1rem;
}
.service-box-area {
  background-color: #ffffff;
  padding: 10rem;
}

.single__service {
  background: #ffffff;
  box-shadow: 0 20px 40px rgba(16, 0, 71, 0.1);
  padding: 50px 25px;
  border-radius: 5px;
}
.single__service:hover .service__icon a {
  color: #ffffff;
  background: var(--apptheme);
  border-color:var(--apptheme);
}
.single__service h4 {
  margin-top: 8px;
  margin-bottom: 16px;
}
@media (max-width: 767px) {
  .single__service h4 {
    margin-top: 15px;
  }
}
.single__service p {
  margin-bottom: 30px;
}

.service__icon a {
  height: 96px;
  width: 96px;
  text-align: center;
  line-height: 92px;
  border: 2px solid var(--apptheme);
  border-radius: 50%;
  display: inline-block;
  font-size: 50px;
  color: var(--blue);
  line-height: 100px;
  -webkit-transition: all 0.5s ease-out 0s;
  -moz-transition: all 0.5s ease-out 0s;
  -ms-transition: all 0.5s ease-out 0s;
  -o-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
}

.service__btn {
  height: 50px;
  border: 1px solid var(--apptheme);
  display: inline-block;
  line-height: 48px;
  padding: 0 24px;
  border-radius: 30px;
  color: #040021;
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
}
.service__btn:hover {
  color: #ffffff;
  background: var(--apptheme);
  border-color: var(--apptheme);
}
.service__btn i {
  font-size: 15px;
  margin-right: 15px;
}

@media (max-width: 767px) {
  .service-box-area {
    padding-top: 60px;
    padding-bottom: 20px;
  }
}

@media (max-width: 767px) {
  .service-box-area-main {
    padding-top: 90px;
  }
}

.service-box-content {
  padding: 0 30px 25px;
  box-shadow: 20px 10px 30px rgba(16, 0, 71, 0.07);
  height: 24rem;
  margin-bottom: 1rem;
  background: #ffffff;
  border-radius: 5px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .service-box-content {
    padding: 0 25px 25px;
  }
}
@media (max-width: 767px) {
  .service-box-content {
    padding: 0 25px 20px;
  }
}
.service-box-content:hover {
  background: #281e62;
}
.service-box-content:hover i {
  background: #ffffff;
}
.service-box-content:hover h4 {
  color: #ffffff;
}
.service-box-content:hover p {
  color: #ffffff;
}

.service-box-content.st-1:hover {
  background: #281e62;
}

.service-box-content.st-3 {
  padding: 0 0 33px 0;
  box-shadow: none;
  border-radius: 0;
}
.service-box-content.st-3:hover {
  background: none;
}
.service-box-content.st-3:hover .service-box-content-icon i {
  background: #ffc400;
  border-radius: 50%;
  border-color: #ffc400;
}
.service-box-content.st-3:hover h4 {
  color: #ffc400;
}
.service-box-content.st-3:hover p {
  color: #8f98a8;
}
.service-box-content.st-3 .service-box-content-icon {
  float: left;
  margin-right: 30px;
}
.service-box-content.st-3 .service-box-content-icon i {
  color: #040021;
  width: 70px;
  height: 70px;
  background: none;
  line-height: 78px;
  border-radius: 0 50% 50% 50%;
  border: 1px solid #e6e6e6;
}
.service-box-content.st-3 .service-box-content-text {
  overflow: hidden;
}
.service-box-content.st-3 .service-box-content-text h4 {
  margin-top: 0px;
  line-height: 1;
  margin-bottom: 35px;
}

.service-box-content-icon i {
  font-size: 40px;
  color: #281e62;
  width: 78px;
  height: 78px;
  background: #159ff4c8;
  text-align: center;
  line-height: 78px;
  border-radius: 0 1 50% 50%;
  display: inline-block;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.service-box-content-icon.st-1 i {
  color: #281e62;
  background: #f1f4fe;
}

.service-box-content-text h4 {
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  margin-top: 20px;
  margin-bottom: 10px;
}
.service-box-content-text p {
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.process-area {
  padding-top: 140px;
  padding-bottom: 165px;
  position: relative;
  background-size: cover;
  background-position: center center;
}
.process-area:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #040021;
  opacity: 0.8;
}

.process-content {
  padding: 0px 20px;
  position: relative;
  z-index: 5;
}

.process-btn {
  display: inline-block;
  position: relative;
}
.process-btn:before {
  position: absolute;
  content: "";
  width: 136px;
  height: 136px;
  background: #ffffff;
  opacity: 0.11;
  border-radius: 50%;
  top: -25px;
  left: -25px;
  animation: animation-pulse-shrink 2s infinite;
}
.process-btn .grb-video {
  height: 85px;
  width: 85px;
  color: orange;
  line-height: 85px;
  font-size: 28px;
  position: relative;
  z-index: 5;
}

.prc-btn {
  position: relative;
}

@media (max-width: 767px) {
  .partners-area {
    padding-bottom: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .partners-logo {
    padding-left: 60px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .partners-logo {
    padding-left: 0;
    padding-right: 100px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 767px) {
  .partners-logo {
    padding-left: 0;
  }
}
@media screen and (max-width:768px){
  .service-box-area{
    padding: 0rem;
  }
}

.single-partner {
  margin-bottom: 40px;
}
@media (max-width: 767px) {
  .single-partner {
    text-align: center !important;
  }
}

@media (max-width: 767px) {
  .service-details-area {
    padding-top: 90px;
    padding-bottom: 20px;
  }
}

.service-details-single-img {
  border-radius: 10px;
  margin-bottom: 30px;
  overflow: hidden;
}
.service-details-single-img img {
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  width: 100%;
}

.service-details-heading h2 {
  text-transform: uppercase;
  margin-top: 5px;
  margin-bottom: 32px;
}

.service-details-content h5 {
  font-size: 18px;
  line-height: 25px;
  text-transform: uppercase;
}
.service-details-content > h4 {
  font-size: 24px;
  line-height: 25px;
  text-transform: uppercase;
}
@media (max-width: 767px) {
  .service-details-content > h4 {
    font-size: 23px;
  }
}

.service-details-content > p {
  margin-bottom: 43px;
}

.execute-list {
  margin-bottom: 42px;
}
.execute-list li {
  padding-left: 35px;
  position: relative;
  color: #040021;
  margin-bottom: 17px;
  font-weight: 500;
}
.execute-list li:before {
  position: absolute;
  content: "";
  font-size: 16px;
  font-family: "Font Awesome 5 Pro";
  color: var(--apptheme);
  left: 0;
  top: 0;
}
.execute-list li:last-child {
  margin-bottom: 0;
}

.service-details-more .service-box-content {
  border: 1px solid #f2f2f2;
  box-shadow: none;
}
.service-details-more .service-box-content-text h4 {
  text-transform: none;
}