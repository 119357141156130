.feature-icon-wrap {
    position: relative;
    padding-bottom: 40px;
    margin: 0px;
}
.feature-icon-wrap .left-side-icon{
    float: left;

}
.feature-icon-wrap .left-side-icon .front-icon{
    font-size:3em;
    color: #03a9f4;
}
.back-icon {
    z-index: -1;
    color: #f0f0f0;
    position: absolute;
    padding-left: 40px;
    left: 0;
    top: 0;
    width: 100px;
    height: 100px;
    line-height: 100px;
    font-size: 100px;
}
.feature-icon-wrap .features-text-right{
    padding-left: 40px;
    overflow: hidden;
}
.features-text-right h3{
    text-transform: uppercase;
    font-weight: 700;
    font-size: 18px;
    letter-spacing: 1px;
}
.services-icons .left-side-icon i{
    width:70px;
    height: 70px;
    line-height: 70px;
    display: inline-block;
    text-align: center;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    border: 1px solid  #03a9f4;
}






.section-padding{
    padding-top: 100px;
    padding-bottom: 40px;
    background-color: white;
    position: relative;
    z-index: 1;
}
.divided30{
    width:100%;
    height: 30px;
}
.padding-bottom30{
    padding-bottom: 30px;
}
.margin-bottom30{
    margin-bottom: 30px;
}

.colored-text{
    color: #03a9f4;
}

.alo{
  font-family: 'Open Sans', sans-serif;
  display: inline-block;
  text-transform: uppercase;
  margin-right: 5px;
}
.section-title{
    position: relative;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 60px;
}
.section-title h1{
    text-transform: uppercase;
    font-weight: 300;
    margin: 0px;
    font-size: 40px;
    margin-bottom: 0px;
    letter-spacing: 1px;
}
.border-line{
    width:40px;
    height: 1px;
    margin-top: 20px;
    display: block;
    text-align: center;
    background-color:  #03a9f4;
    margin-left: auto;
    margin-right: auto;
}
.subtitle-caption{
    font-weight: 400;
    margin-top: 20px;
    font-size: 14px;
    margin-bottom: 0;
    line-height: 25px;
    text-transform: capitalize;
}
.center-title{
    margin-bottom: 40px;
    text-align: center;
    text-transform: capitalize;
    font-weight: 300;
    letter-spacing: 2px;
}
.no-padding{
    padding: 0px;
}