.Jwelback{
    background-image: url(../../../assets/jwelback.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    height: 60rem;
    background-position: center;
}
@media screen and (max-width:768px){
.billingimg-23{
    height: 13.5rem!important;
    padding-left: 0!important;
}

.billingimg-12{
 height: 15rem!important;
}
}