.digitalimg{
    height: 22rem;
  }
  .softtop-1{
    padding-top: 2.5rem;
  }
  @media (max-width: 767px){
  .digitalimg{
    height: 13rem;
  }
}
.digitalback{
    background-image: url(../../../assets/digitalback.jpg);
    height: 60rem;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}