.Billback{
    background-image: url(../../../assets/billback1.png);
    background-repeat: no-repeat;
    background-size: cover;
    height: 60rem;
    background-position: center;
}

.service_section {
    /* background-image: url(../images/service-bg.jpg); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: 85% 85%;
  background-color: white
  }
  
  .service_section .heading_container {
    margin-bottom: 35px;
    text-align: center;
  }
  
  .service_section .img-container {
    padding: 20px 0 20px 20px;
    background: -webkit-gradient(linear, left top, right top, color-stop(60%, #ffffff), color-stop(60%, transparent));
    background: linear-gradient(to right, #ffffff 60%, transparent 60%);
  }
  
  .service_section .img-container .img-box img {
    width: 100%;
  }
  
  .service_section .row {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  
  .service_section .detail-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    border: none;
  }
  
  .service_section .detail-container .detail-box {
    background-color: #373636;
    color: #ffffff;
    text-align: center;
    text-transform: uppercase;
    min-width: 200px;
    height: 200px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin: 15px;
  }
  
  .service_section .detail-container .detail-box h4 {
    margin: 0;
  }
  
  .service_section .detail-container .detail-box:hover, .service_section .detail-container .detail-box.active {
    background-color: #fbac2e;
    cursor: pointer;
  }
  
  .service_section .btn-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin-top: 45px;
  }
  
  .service_section .btn-box a {
    display: inline-block;
    padding: 10px 45px;
    background-color: #373636;
    color: #ffffff;
    border: 1.5px solid #373636;
    border-radius: 5px;
  }
  
  .service_section .btn-box a:hover {
    background-color: transparent;
    color: #373636;
  }
  
.agency_section .agency_container {
    background: 
    linear-gradient(
      rgba(247, 246, 246, 0.45), 
      rgba(13, 13, 13, 0.45)
    ),

    url(../../../assets/bill2);


   
    background-size: cover;
    /* margin: 0 45px; */
    padding: 90px 75px;
  }
  
  .agency_section .agency_container .box {
    background-color: rgba(0, 0, 0, 0.8);
    color: #ffffff;
    width: 60%;
    padding: 120px 90px;
  }
  
  .agency_section .agency_container .box .detail-box .heading_container {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    text-align: left;
  }
  
  .agency_section .agency_container .box .detail-box p {
    margin-top: 35px;
  }
  
  .agency_section .agency_container .box .detail-box a {
    display: inline-block;
    padding: 10px 45px;
    background-color: #ffffff;
    color: #000000;
    border: 1.5px solid #ffffff;
    border-radius: 5px;
    margin-top: 45px;
  }
  
  .agency_section .agency_container .box .detail-box a:hover {
    background-color: transparent;
    color: #ffffff;
  }
  @media screen and (max-width:768px){
    .agency_section .agency_container{
   padding: 1rem;
    }
    .billing-1,  .billing-2,.billing-3,.billing-4{
      padding: 1rem!important;
    }
    .billingimg-1{
      width: 23rem;
    }
    .agency_section .agency_container .box {
      padding: 24px 20px;
    }
    .billingimg-2,.billingimg-3{
      height: 12rem!important;
      padding-left: 0!important;
    }
    .billingimg-4{
      height: 16rem!important;
    
    }
    
  }