.webdevback{
    background-image: url(../../../assets/webdev1.jpeg);
    height: 40rem;
}
.services {
    background-color: #fff;
    padding-top: 80px;
}

.services .services_box {
    margin-bottom: 30px;
}

.services .services_box img {
    display: block;
}

.services .services_box h3 {
    margin-top: 20px;
    color: #323639;
    font-size: 17px;
    font-weight: 700;
    line-height: 20px;
    text-transform: uppercase;
    border-bottom: #020001 solid 3px;
    display: inline-block;
    padding-bottom: 6px;
    transition: ease-in all 0.5s;
}

.services .services_box p {
    color: #323639;
    font-size: 17px;
    padding-top: 15px !important;
    padding: 0;
    line-height: 25px;
}

.services_box h3:hover {
    transition: ease-in all 0.5s;
    color: var(--blue);
    border-bottom: var(--blue) solid 3px;
}
@media screen and (max-width:768px){
    .webdev-1, .webdev-3,.webdev-2,.webdev-4,.webdev-5 {
        padding: 2rem!important;
    }
    .webdevimg-1{
        padding-left: 0!important;
    }
    .webdevimg-5{
        margin-left: -11rem;
    }
    .webdevimg-4{
        visibility: hidden;
    }
   
   
}
