.it-sol{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.featuresss{
    padding-bottom: 5rem;
}
.container .card{
position: relative;
width: 300px;
height: 400px;
background: var(--apptheme);
display: flex;
justify-content: center;
align-items: center;
margin: 30px;
box-shadow: 0 20px 50px black;
overflow: hidden;
}
/* .container:hover .card{
    opacity: 0.5;
}
.container .card:hover{
    opacity: 1;
} */
.container .card::before{
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    bottom: 2px;
    width: 50%;
    background: rgba(7, 7, 7, 0.445);
    pointer-events: none;
   
}
.container .card .content{
    padding: 30px;
    text-align: center;
    color: aliceblue;
}
.container .card .content h2{
    position: absolute;
    font-size: 3.5em;
    right: 30px;
    font-weight: 800;
    color: aliceblue;
    z-index: 1;
    opacity: 0.1;
    transition: 0.5s;
    top: 4rem;

}
.container .card:hover .content h2{
    opacity: 1;
    transform: translateY(-60px);
}
.container.card:hover .content h3,
.container .card:hover .content p{
    opacity: 1;
}
.container .card .content button{
    display: inline-block;
    margin-top: 15px;
    padding: 8px 15px;
    background: white;
    color: black;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 600;
}
.container .card span{
    transition: 0.5;
    opacity: 0;
}
.container .card:hover span{
   opacity: 1; 
}
.container .card span:nth-child(1)
{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background: linear-gradient(to right,transparent,rgb(18, 177, 245));
    animation: animate1 2s linear infinite;
}
@keyframes animate1{
    0%{
        transform:translateX(-100%)
    }
    100%{
        transform:translateX(100%)
    }
}
.container .card span:nth-child(2)
{
    position: absolute;
    top: 0;
    right: 0;
    width: 3px;
    height: 100%;
    background: linear-gradient(to bottom,transparent,rgb(18, 177, 245));
    animation: animate2 2s linear infinite;
    animation-delay: 1s;
}
@keyframes animate2{
    0%{
        transform:translateX(-100%)
    }
    100%{
        transform:translateX(100%)
    }
}
.container .card span:nth-child(3)
{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background: linear-gradient(to left,transparent,rgb(18, 177, 245));
    animation: animate3 2s linear infinite;
}
@keyframes animate3{
    0%{
        transform:translateX(100%)
    }
    100%{
        transform:translateX(-100%)
    }
}
.container .card span:nth-child(4)
{
    position: absolute;
    top: 0;
    left: 0;
    width: 3px;
    height: 100%;
    background: linear-gradient(to top,transparent,rgb(18, 177, 245));
    animation: animate4 2s linear infinite;
    animation-delay: 1s;
}
@keyframes animate4{
    0%{
        transform:translateX(100%)
    }
    100%{
        transform:translateX(-100%)
    }
}

.featuress {
    padding: 3rem;
    margin-left: 5rem;
    margin-right: 5rem;
    text-align: center;
    color: aliceblue;
}
.featuress h6{
    font-size: 1.05rem!important;
    font-weight: 450;
    padding: 1rem;
}
.featuress h1{
    padding: 1rem;
}
@media screen and (max-width:768px){
    .featuress{
        margin-left: 0;
        margin-right:0 ;
        padding: 2rem;
    }
}