.inventimg{
    width: 38rem;
}
.inventimg2{
    background-image: url(../../../assets/invent2.jpg);
    height: 25rem;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 1rem;
}
.card-invent{
   padding: 2rem;
   box-shadow: -1px 2px 3px 4px #e5e5e5;
   margin-top: 5rem;
}
.card-invent p{
padding: 6rem;
font-size: 1.2rem;
}
@media (max-width: 767px){
.inventimg {
    width: 21rem;
}
.card-invent p {
    padding: 1rem;
  
}
}